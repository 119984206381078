<template>
  <v-card>
    <v-card-title class="text-h6">
      <div>
        {{ $t("labels.sku") }}: {{ item.sku }} - {{ $t("labels.barcode") }}:
        {{ item.customer_goods_barcode }}
      </div>
      <v-spacer></v-spacer>
      <div>
        <v-btn color="red darken-1" text @click="cancel">
          {{ $t("labels.cancel") }}
        </v-btn>
        <v-btn color="green darken-1" text @click="confirm">
          {{ $t("labels.confirm") }}
        </v-btn>
      </div>
    </v-card-title>

    <template v-if="checkPermission(['goods_report_size_edit'])">
      <v-card-text
        >{{ $t("labels.update_goods_property") }} -
        {{ $t("labels.created_at") }}:
        {{ formatDateTime(item.time_created) }}
      </v-card-text>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="itemProperty.name"
              class="c-input-xs"
              type="text"
              :label="$t('labels.goods_name')"
              dense
              outlined
              clearable
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="itemProperty.sub_barcode"
              class="c-input-xs"
              type="text"
              :label="$t('labels.sub_barcode')"
              dense
              outlined
              clearable
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="itemProperty.description"
              class="c-input-xs"
              type="text"
              :label="$t('labels.goods_description')"
              dense
              outlined
              clearable
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              v-model="itemProperty.size"
              class="c-input-xs"
              type="text"
              :label="$t('labels.size')"
              dense
              outlined
              clearable
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="8">
            <v-row>
              <v-col cols="6" md="3">
                <v-text-field
                  v-model.number="itemProperty.d_size"
                  class="c-input-xs"
                  type="number"
                  :label="$t('labels.d_size')"
                  dense
                  outlined
                  clearable
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="3">
                <v-text-field
                  v-model.number="itemProperty.r_size"
                  class="c-input-xs"
                  type="number"
                  :label="$t('labels.r_size')"
                  dense
                  outlined
                  clearable
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="3">
                <v-text-field
                  v-model.number="itemProperty.c_size"
                  class="c-input-xs"
                  type="number"
                  :label="$t('labels.c_size')"
                  dense
                  outlined
                  clearable
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="3">
                <v-text-field
                  v-model.number="itemProperty.pre_weight"
                  class="c-input-xs"
                  type="number"
                  :label="$t('labels.pre_weight')"
                  dense
                  outlined
                  clearable
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <!--<v-col cols="12" md="4">
            <v-text-field v-model.number="itemProperty.weight" class="c-input-xs mb-3" type="number"
                          label="Weight (gram)" dense outlined clearable hide-details></v-text-field>
          </v-col>-->
          <v-col cols="12" md="4">
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  v-model="itemProperty.stamp_size"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                  :label="$t('labels.stamp_size')"
                  :placeholder="$t('labels.stamp_size')"
                  :items="stampOptions"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model.number="itemProperty.max_print_quantity"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                  :label="$t('labels.max_print_quantity')"
                  :placeholder="$t('labels.max_print_quantity')"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" md="4">
            <v-autocomplete
              class="c-input-xs"
              v-model="itemProperty.transport"
              :items="transportOptions"
              :label="$t('labels.delivery_method')"
              dense
              outlined
              clearable
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="itemProperty.url_images"
              class="c-input-xs"
              type="text"
              :label="$t('labels.enter_image_link')"
              dense
              outlined
              clearable
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-btn
              style="height: 40px"
              color="warning"
              outlined
              block
              @click="$refs.inputUploadFile.click()"
            >
              <v-icon>mdi-upload</v-icon> {{ $t("labels.or_upload_image") }}
            </v-btn>
            <input
              type="file"
              ref="inputUploadFile"
              accept="image/*"
              @change="onInputFileChange"
              class="d-none"
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-textarea
              v-model="itemProperty.comment"
              class="c-input-xs"
              rows="3"
              :label="$t('labels.note')"
              dense
              outlined
              hide-details
            ></v-textarea>
          </v-col>
          <v-col cols="12" md="4">
            <div>
              <v-checkbox
                v-model.number="itemProperty.use_pre_weight"
                class="c-input-xs mt-0"
                type="number"
                :label="$t('labels.use_pre_weight')"
                dense
                outlined
                clearable
                hide-details
                :readonly="!itemProperty.pre_weight"
                :disabled="!itemProperty.pre_weight"
                :value="1"
              ></v-checkbox>
            </div>
            <div>
              <v-checkbox
                v-model.number="itemProperty.uid_printed_on_item"
                class="c-input-xs mt-0"
                type="number"
                :label="$t('labels.uid_printed_on_item')"
                dense
                outlined
                clearable
                hide-details
                :value="1"
              ></v-checkbox>
            </div>
            <div v-if="checkPermission(['super_admin'])">
              <div>
                <v-checkbox
                  v-model.number="itemProperty.uid_scan_require"
                  class="c-input-xs mt-0"
                  type="number"
                  :label="$t('labels.uid_scan_require')"
                  dense
                  outlined
                  clearable
                  hide-details
                  :value="1"
                ></v-checkbox>
              </div>
              <div>
                <v-checkbox
                  v-model.number="itemProperty.no_label"
                  class="c-input-xs mt-0"
                  type="number"
                  :label="$t('labels.no_label')"
                  dense
                  outlined
                  clearable
                  hide-details
                  :value="1"
                ></v-checkbox>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <ImageViewer :url="itemProperty.url_images" height="60px" />
          </v-col>
        </v-row>
      </v-card-text>
    </template>

    <template v-if="checkPermission(['goods_report_cost_edit'])">
      <v-card-text>{{ $t("labels.update_price_fee") }}</v-card-text>

      <v-card-text>
        <v-row>
          <v-col cols="6" md="4">
            <v-text-field
              v-model.number="itemProperty.cost_custom_import"
              class="c-input-xs"
              type="number"
              :label="$t('labels.cost_custom_import')"
              dense
              outlined
              clearable
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              v-model.number="itemProperty.cost_custom_store"
              class="c-input-xs"
              type="number"
              :label="$t('labels.cost_custom_store')"
              dense
              outlined
              clearable
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              v-model.number="itemProperty.cost_custom_export"
              class="c-input-xs"
              type="number"
              :label="$t('labels.cost_custom_export')"
              dense
              outlined
              clearable
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              v-model.number="itemProperty.insurance_value"
              class="c-input-xs"
              type="number"
              :label="$t('labels.insurance_value')"
              dense
              outlined
              clearable
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="6" md="4">
            <v-text-field
              v-model.number="itemProperty.wholesale_price"
              class="c-input-xs"
              type="number"
              :label="$t('labels.wholesale_price')"
              dense
              outlined
              clearable
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              v-model.number="itemProperty.price"
              class="c-input-xs"
              type="number"
              :label="$t('labels.goods_price')"
              dense
              outlined
              clearable
              hide-details
            ></v-text-field>
          </v-col>

          <!--<v-col cols="12" md="4">
            <v-text-field v-model="itemProperty.sub_barcode" class="c-input-xs mb-3" type="text"
                          label="Sub-Code" dense outlined clearable hide-details></v-text-field>
          </v-col>-->
        </v-row>
      </v-card-text>
    </template>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { TRANSPORT_OPTIONS } from "@/libs/const";
import { compressAccurately } from "image-conversion";
import { formatDateTime } from "@/libs/helpers";

export default {
  name: "UpdateProperty",
  components: {
    ImageViewer: () => import("@/components/goods/ImageViewer"),
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    itemProperty: {},
    transportOptions: [...TRANSPORT_OPTIONS],
    stampOptions: [
      {
        text: "50x20",
        value: "50x20",
      },
      {
        text: "50x40",
        value: "50x40",
      },
      {
        text: "30x15",
        value: "30x15",
      },
      {
        text: "32x18",
        value: "32x18",
      },
    ],
  }),
  methods: {
    formatDateTime,
    async onInputFileChange(e) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      let files = e.target.files || e.dataTransfer.files;
      const file = files[0];
      const resBlob = await compressAccurately(file, {
        size: 250,
        type: "image/jpeg",
      });
      const newFile = new File(
        [resBlob],
        `${this.item.customer_goods_barcode}.jpg`,
        { type: "image/jpeg" }
      );

      let fd = new FormData();
      fd.append("file", newFile);

      try {
        const { data } = await httpClient.post(`/common/v1/upload-image`, fd);
        this.itemProperty = {
          ...this.itemProperty,
          url_images: `${process.env.VUE_APP_FILE_CDN_URL}/${data}`,
        };
        this.isLoading = false;
      } catch (err) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
        this.$refs.inputUploadFile.value = null;
      }
    },
    cancel() {
      this.$emit("cancel");
    },
    confirm() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        httpClient.post("/goods/v1/update-property", {
          id: this.item.id,
          ...this.itemProperty,
        });
        this.isLoading = false;
        document.getElementById("success_sound_player").play();
        this.cancel();
        this.$emit("refreshData");
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
        document.getElementById("error_sound_player").play();
      }
    },
  },
  mounted() {
    this.itemProperty = {
      ...this.item,
      use_pre_weight: this.item.use_pre_weight ? 1 : 0,
      uid_printed_on_item: this.item.uid_printed_on_item ? 1 : 0,
      uid_scan_require: this.item.uid_scan_require ? 1 : 0,
    };
  },
};
</script>

<style scoped></style>
